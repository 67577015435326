<template>
  <CustomDialog
    ref="dialog"
    :open.sync="open"
    title="New Order Form Template"
    @button1="clear_and_close"
    @button2="save"
    button2-text="Save"
    :extra-class="`pa-2`"
    :main-btn-disabled="!all_valid"
  >
    <template #content>
      <v-card-text>
        <v-form v-model="valid" ref="form">
          <label class="primary--text subtitle-2">Template Name</label>
          <v-text-field
            hide-details="auto"
            :rules="titleRules"
            required
            dense
            outlined
            v-model="name"
            :counter="60"
          />
          <FormMaker v-model="form" :with-templates="false" :templates="[]" />
        </v-form>
      </v-card-text>
    </template>
  </CustomDialog>
</template>

<script>
import CustomDialog from "@/common/BaseComponents/CustomDialog/CustomDialog.vue";
import FormMaker from "./FormMaker.vue";

export default {
  name: "OrderTemplateDialog",
  components: { FormMaker, CustomDialog },
  props: {
    dialog: { type: Boolean, default: false },
  },
  data: () => ({
    open: false,
    valid: false,
    name: null,
    form: [],
    titleRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length >= 5) || "Name must be atleast 5 characters",
      (v) =>
        (v && v.length <= 60) ||
        "Name must be less than or equal to 60 characters",
    ],
  }),
  watch: {
    dialog(val) {
      this.open = val;
      if (val) {
        this.form = [];
        this.name = null;
      }
    },
    open(val) {
      this.$emit("update:dialog", val);
    },
  },
  computed: {
    all_valid() {
      return this.valid && this.form.length > 0;
    },
  },
  methods: {
    clear_and_close() {
      this.open = false;
    },
    save() {
      let payload = {
        name: this.name,
        form: this.form,
      };
      this.$emit("save", payload);
    },
  },
};
</script>

<style scoped>
</style>