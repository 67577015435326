<template>
  <v-card flat class="form-maker-x">
    <v-card-text>
      <draggable
        class="form-drag"
        v-model="structures"
        scroll-sensitivity="600"
        force-fallback="true"
      >
        <v-row
          v-for="(item, pIndex) in structures"
          :key="item.id"
          dense
          align="center"
          justify="center"
          class="cursor-drag"
        >
          <v-col :cols="mdAndUp ? 10 : 9">
            <div v-if="item.type === `checkbox`">
              <label v-if="item.label" class="primary--text fw-700">
                {{ item.label }}
                <span v-if="item.hasOwnProperty('required') && item.required">
                  *
                </span>
              </label>
              <v-checkbox
                dense
                hide-details="auto"
                :label="item.text"
                v-model="item.dummy_value"
              />
            </div>
            <div v-else-if="item.type === `radio_group`">
              <label v-if="item.label" class="primary--text fw-700">
                {{ item.label }}
              </label>
              <v-radio-group
                v-model="item.dummy_value"
                :row="item.direction === 'row'"
                :column="item.direction !== 'row'"
                :mandatory="item.required"
              >
                <v-radio
                  v-for="(option, i) in item.items"
                  :key="i"
                  :label="option"
                  :value="option"
                />
              </v-radio-group>
            </div>
            <div v-else-if="item.type === `checkboxes`">
              <label v-if="item.label" class="primary--text fw-700">
                {{ item.label }}
              </label>
              <v-row no-gutters dense v-if="item.direction === 'row'">
                <v-col md="12" class="d-flex flex-wrap">
                  <v-checkbox
                    v-model="item.dummy_value"
                    hide-details="auto"
                    class="mr-3 my-1"
                    v-for="(option, i) in item.items"
                    :key="i"
                    :label="option"
                    :value="option"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters dense v-else>
                <v-col md="12">
                  <v-checkbox
                    v-model="item.dummy_value"
                    hide-details="auto"
                    v-for="(option, i) in item.items"
                    :key="i"
                    class="my-1"
                    :label="option"
                    :value="option"
                  />
                </v-col>
              </v-row>
            </div>
            <div v-else>
              <label v-if="item.label" class="primary--text fw-700">
                {{ item.label }}
              </label>
              <component
                :placeholder="item.placeholder"
                :type="item.tag_type"
                dense
                v-model="item.dummy_value"
                outlined
                hide-details="auto"
                :is="item.tag"
                :required="item.required"
                :rows="item.rows"
                :items="item.items"
              />
            </div>
          </v-col>
          <div class="d-flex align-self-center mt-4 align-center">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="removeField(pIndex)"
                  class="my-1"
                  color="#223A6B"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>Remove</span>
            </v-tooltip>
            <v-menu
              v-model="item.hover"
              :close-on-content-click="false"
              :nudge-width="200"
              offset-y
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="my-1" color="#223A6B">
                  mdi-pencil-outline
                </v-icon>
              </template>
              <v-card flat>
                <v-card-text>
                  <h3
                    class="mb-2 text-center"
                    style="color: #223a6b; font-size: 16px; font-weight: 600"
                  >
                    <!-- <v-icon left>{{ item.icon }}</v-icon> -->
                    {{ item.type | ucwords }} Properties
                  </h3>
                  <v-text-field
                    v-model="item.label"
                    label="Question"
                    hide-details="auto"
                    outlined
                    dense
                    v-if="item.hasOwnProperty('label')"
                    style="margin-bottom: 20px !important"
                    class="mb-1"
                  />
                  <v-text-field
                    v-model="item.text"
                    label="Value"
                    hide-details="auto"
                    dense
                    style="margin-bottom: 20px !important"
                    outlined
                    v-if="
                      item.type !== 'paragraph' && item.hasOwnProperty('text')
                    "
                    class="mb-1"
                  />
                  <v-text-field
                    v-model="item.rows"
                    label="Textarea rows"
                    style="margin-bottom: 20px !important"
                    hide-details="auto"
                    dense
                    outlined
                    v-if="item.hasOwnProperty('rows')"
                    class="mb-1"
                    type="number"
                  />

                  <v-text-field
                    v-model="item.placeholder"
                    label="Placeholder"
                    style="margin-bottom: 20px !important"
                    hide-details="auto"
                    dense
                    outlined
                    v-if="item.hasOwnProperty('placeholder')"
                    class="mb-1"
                  />
                  <v-select
                    v-model="item.width"
                    label="Width (# of columns)"
                    :items="Array.from(Array(12), (_, i) => i + 1)"
                    menu-props="auto"
                    style="margin-bottom: 20px !important"
                    hide-details
                    single-line
                    v-if="item.hasOwnProperty('width')"
                    dense
                    outlined
                    class="mb-1"
                  />
                  <v-select
                    v-model="item.align"
                    label="Alignment"
                    append-icon="mdi-align-horizontal-center"
                    item-value="value"
                    style="margin-bottom: 20px !important"
                    :items="alignItems"
                    menu-props="auto"
                    hide-details
                    v-if="item.hasOwnProperty('align')"
                    dense
                    outlined
                    class="mb-1"
                  />
                  <v-checkbox
                    v-model="item.required"
                    label="Required?"
                    hide-details="auto"
                    style="margin-bottom: 20px !important; color: #223a6b"
                    class="step-2-form-edit-checkbox"
                    v-if="item.hasOwnProperty('required')"
                  />
                  <div
                    v-if="item.hasOwnProperty('items')"
                    style="margin-bottom: 20px !important"
                  >
                    <small>Options</small>
                    <div v-for="(intem, index) in item.items" :key="index">
                      <v-text-field
                        label="Enter option"
                        v-model="item.items[index]"
                        hide-details="auto"
                        dense
                        outlined
                        class="mb-1"
                      >
                        <template v-slot:append-outer>
                          <v-icon @click="removeFromItems(index, item)">
                            mdi-delete
                          </v-icon>
                        </template>
                      </v-text-field>
                    </div>
                    <v-text-field
                      label="Enter other option"
                      v-model="new_option"
                      hide-details="auto"
                      dense
                      outlined
                      class="mb-1"
                    >
                      <template v-slot:append-outer>
                        <v-icon
                          :disabled="!new_option"
                          :color="new_option ? `success` : `grey`"
                          @click="addToItem(item)"
                        >
                          mdi-check-circle-outline
                        </v-icon>
                      </template>
                    </v-text-field>
                  </div>
                  <div
                    v-if="item.hasOwnProperty('direction')"
                    style="margin-bottom: 20px !important"
                  >
                    <small>Direction</small>
                    <v-radio-group v-model="item.direction" row>
                      <v-radio label="Row" value="row" />
                      <v-radio label="Column" value="column" />
                    </v-radio-group>
                  </div>
                </v-card-text>
              </v-card>
            </v-menu>
          </div>
        </v-row>
      </draggable>
    </v-card-text>
    <v-card-text class="d-flex flex-column align-center">
      <v-menu bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            large
            class="text-none my-1 mx-auto py-5"
            v-bind="attrs"
            elevation="1"
            color="white"
            v-on="on"
          >
            <v-icon left>mdi-plus</v-icon>
            <span class="fw-700 fs-14" v-if="!withTemplates">Add Field</span>
            <span class="fw-700 ls-1 fs-14" v-else>
              Add Field or <br v-if="!mdAndUp" />Select from Template
            </span>
            <v-icon right>mdi-menu-swap</v-icon>
          </v-btn>
        </template>
        <v-card flat>
          <v-card-text class="pa-1">
            <v-row align="stretch" justify="center" dense>
              <v-col>
                <v-list dense>
                  <v-list-item
                    v-for="(item, index) in items"
                    :key="index"
                    @click="addField(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      {{ item.type | ucwords }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-divider vertical v-if="withTemplates" />
              <v-col v-if="withTemplates">
                <v-list dense v-if="templates.length > 0">
                  <v-list-item
                    v-for="item in templates"
                    :key="item.id"
                    @click="addTemplate(item)"
                  >
                    <v-list-item-title>
                      {{ item.name | ucwords }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
                <Empty v-else headline="No template yet" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-card-text> 
  </v-card>
</template>

<script>
import _cloneDeep from "lodash/cloneDeep";
import { v4 as uuidv4 } from "uuid";
import draggable from "vuedraggable";

export default {
  name: "FormMaker",
  components: {
    draggable,
  },
  props: {
    value: Array,
    templates: { type: Array, default: () => [] },
    withTemplates: { type: Boolean, default: true },
  },
  computed: {
    text() {
      return {
        id: null,
        type: "text",
        tag: "v-text-field",
        tag_type: "text",
        label: "Untitled Question",
        icon: "mdi-alpha-t-box-outline",
        show_icon: false,
        placeholder: "Your answer",
        value: null,
        dummy_value: null,
        required: false,
        hover: false,
      };
    },
    textarea() {
      return {
        id: null,
        type: "textarea",
        tag: "v-textarea",
        label: "Untitled Question",
        icon: "mdi-format-line-weight",
        show_icon: false,
        placeholder: "Your answer",
        value: null,
        dummy_value: null,
        rows: 2,
        required: false,
        hover: false,
      };
    },
    dropdown() {
      return {
        id: null,
        type: "dropdown",
        tag: "v-select",
        label: "Select",
        icon: "mdi-arrow-down-drop-circle-outline",
        show_icon: false,
        placeholder: "Select your answer",
        value: null,
        dummy_value: null,
        required: false,
        hover: false,
        items: ["Select 1", "Select 2"],
      };
    },
    radio_group() {
      return {
        id: null,
        type: "radio_group",
        tag: "v-radio-group",
        label: "Select",
        icon: "mdi-radiobox-marked",
        required: true,
        show_icon: false,
        value: null,
        dummy_value: null,
        hover: false,
        direction: "row", //or column
        items: ["Option 1", "Option 2"],
      };
    },
    checkboxes() {
      return {
        id: null,
        type: "checkboxes",
        tag: "checkboxes",
        label: "Select",
        icon: "mdi-checkbox-multiple-marked-outline",
        show_icon: false,
        value: [],
        dummy_value: [],
        required: false,
        hover: false,
        direction: "row", //or column
        items: ["Option 1", "Option 2"],
      };
    },
    checkbox() {
      return {
        id: null,
        type: "checkbox",
        tag: "v-checkbox",
        label: "Question",
        icon: "mdi-checkbox-marked-outline",
        show_icon: false,
        text: "Checkbox",
        hover: false,
        value: null,
        dummy_value: null,
      };
    },
    file_upload() {
      return {
        id: null,
        type: "file_upload",
        tag: "v-file-input",
        tag_type: "file",
        label: "Upload file",
        icon: "mdi-paperclip",
        show_icon: false,
        placeholder: "Select file",
        value: null,
        dummy_value: null,
        props: [],
        required: false,
        hover: false,
        error: false,
        multiple: false,
        max: 1,
      };
    },
    items() {
      return [
        this.text,
        this.textarea,
        this.dropdown,
        this.radio_group,
        this.checkboxes,
        this.checkbox,
        this.file_upload,
      ];
    },
  },
  watch: {
    value: {
      handler(val) {
        this.structures = val;
      },
      deep: true,
      immediate: true,
    },
    structures(val) {
      this.$emit("input", val);
    },
  },
  data: () => ({
    structures: [],
    new_option: null,
    debug_on: false,
  }),
  methods: {
    addField(item) {
      let clone = _cloneDeep(item);
      clone.id = uuidv4();
      this.structures.push(clone);
    },
    addTemplate(item) {
      let form = _cloneDeep(item.form);
      let vm = this;
      form.forEach(function (field, index) {
        field.id = uuidv4();
        vm.structures.push(field);
      });
    },
    removeField(index) {
      this.structures.splice(index, 1);
    },
    removeFromItems(index, item) {
      if (item) {
        item.items.splice(index, 1);
      }
    },
    addToItem(item) {
      if (item) {
        item.items.push(this.new_option);
        this.new_option = null;
      }
    },
    alignClass(align) {
      if (align === "right") return "ml-auto";
      else if (align === "center") return "mx-auto";
      else return "mr-auto";
    },
  },
};
</script>
<style>
.form-maker-x .step-2-form-edit-checkbox label {
  color: #223a6b !important;
  font-weight: 700 !important;
}
</style>
<style scoped>
.form-maker-x .cursor-drag {
  cursor: move;
}
.form-maker-x .subtitle-1 {
  color: #223a6b;
  font-weight: 700;
}
</style>