import { mapGetters } from 'vuex'
import request from '@/services/axios_instance'

export const offers_mixin = {
    data: () => ({
        activeProduct: null,
        product_view_dialog: false, //required productviewdialog components
        product_delete_dialog: false,
        product_share_dialog: false,
        order_handlers: []
    }),
    created() {
        this.getOrderHandlers()
    },
    computed: {
        ...mapGetters(['user']),
        ...mapGetters('cart', ['mainStoreCompanies']),
        canAddProduct() {
            return this.user.is_admin || this.$_permissions.can('offers.create')
        },
        user_is_assigned() {
            return this.order_handlers.findIndex(i => i.id === this.user.id)
        },
        can_view_incoming_orders() {
            return this.user.is_admin || this.user.is_manager || ~this.user_is_assigned
        }
    },
    methods: {
        getOrderHandlers() {
            request
                .get(`api/companies/${this.user.company_id}/order-handlers`)
                .then(({ data }) => {
                    this.order_handlers = data
                })
        },
        is_video(attachment) {
            return !!attachment.filetype.includes('video')
        },
        getImage(attachment) {
            return attachment.filetype === 'image/gif' ||
                !attachment.hasOwnProperty('thumbnail') ?
                attachment.url_inline :
                attachment.thumbnail
        },
        viewProduct(item) {
            this.activeProduct = item
            this.$router.push({
                    name: 'offers-by-product',
                    params: { id: item.slug },
                    query: { redirect_uri: this.$route.fullPath }
                })
                // this.product_view_dialog = true
        },
        editProduct(item) {
            this.$router.push({
                name: 'product_builder',
                params: {
                    id: item.id
                }
            })
        },
        canEdit(item) {
            return this.user.id === item.user_id
        },
        canAddPrice(item) {
            if (!item) return false
            if (item.company_id === this.user.company.id) return false
            if (this.user.is_admin || this.$_permissions.can('offers|create'))
                return true
            return false
        },
        canAddCategoryPrice() {
            let found = this.mainStoreCompanies.findIndex(
                (i) => Number(i) === Number(this.user.company.id)
            )
            if (~found) return false
            return this.user.is_admin || this.$_permissions.can('offers|create')
        },
        confirmDeleteProduct(item, cb) {
            this.activeProduct = item
                //   this.product_delete_dialog = true
            this.appConfirmation(
                `Are you sure you want to delete this product?<br> 
        This will also delete all pending orders of this product `,
                () => {
                    this.deleteProduct(cb)
                },
                null,
                'Yes. Delete'
            )
        },
        deleteProduct(cb) {
            if (this.activeProduct) {
                request
                    .delete(`api/products/${this.activeProduct.id}`)
                    .then(({ data }) => {
                        if (typeof cb === 'function') cb(data)
                        this.activeProduct = null
                    })
                    .finally(() => {
                        this.product_delete_dialog = false
                    })
            }
        },
        canDelete(item) {
            return this.user.id === item.user_id
        },
        canShare(item) {
            return item.shareable || true
        },
        shareProduct(item) {
            this.activeProduct = item
            this.product_share_dialog = true
        },
        likeUnlikeProduct(item, cb) {
            request.post(`api/likes/${item.id}`).then(({ data }) => {
                if (typeof cb === 'function') {
                    cb(data)
                }
                this.appSnackbar(data.message)
            })
        },

    }
}