<template>
  <div>
    <CustomDialog
      v-model="dialog"
      @button1="close"
      :title="title"
      :button2-text="saveButtonLabel"
      @button2="save"
      :main-btn-disabled="!isValid"
      :max-width="800"
    >
      <template v-slot:content>
        <v-card flat>
          <v-row dense>
            <v-col :cols="mdAndUp ? 5 : 12">
              <v-sheet
                color="whitesmoke"
                class="d-flex rounded-5 align-center py-5 fill-height flex-column justify-center fullwidth"
              >
                <v-avatar
                  size="200"
                  class="cursor-pointer rounded-10 mb-2"
                  @click.stop="iconDialog = true"
                >
                  <v-img
                    v-if="mapping.attachment"
                    contain
                    :src="mapping.attachment.url_inline"
                  ></v-img>
                  <v-img
                    v-else
                    contain
                    :src="require('@/assets/temp/image-placeholder.png')"
                  ></v-img>
                </v-avatar>
                <strong class="primary--text">Select Preview (Required)</strong>
              </v-sheet>
            </v-col>
            <v-col :cols="mdAndUp ? 7 : 12">
              <div class="d-flex flex-column">
                <label class="primary--text subtitle-2 mt-2">
                  Category Title (Required)
                </label>
                <v-text-field
                  v-model="mapping.title"
                  :rules="titleRules"
                  outlined
                  dense
                  :counter="60"
                  hide-details="auto"
                ></v-text-field>
                <label class="primary--text mt-2 subtitle-2">
                  Description (Optional)
                </label>
                <v-textarea
                  v-model="mapping.description"
                  outlined
                  dense
                  rows="4"
                  hide-details="auto"
                ></v-textarea>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </template>
    </CustomDialog>
    <ImageCropper
      @file="setIcon"
      title="Upload Icon"
      width="200"
      height="220"
      v-model="iconDialog"
      value-format="attachment"
    >
    </ImageCropper>
  </div>
</template>
<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import ImageCropper from '@/common/Dialogs/ImageCropper.vue'
import _ from 'lodash'
export default {
  name: 'CategoryDialog',
  components: {
    CustomDialog,
    ImageCropper
  },
  props: {
    value: { type: Boolean, default: false },
    item: { type: Object }
  },
  computed: {
    isEdit() {
      return this.item ? true : false
    },
    title() {
      return this.item ? `Update ${this.item.title}` : 'New Category'
    },
    saveButtonLabel() {
      return this.isEdit ? 'Update' : 'Save'
    },
    isValid() {
      return this.mapping.title && this.mapping.attachment ? true : false
    }
  },
  data: () => ({
    iconDialog: false,
    dialog: false,
    mapping: {
      description: null,
      title: null,
      attachment: null
    },
    titleRules: [
      (v) => !!v || 'Title is required',
      (v) => (v && v.length >= 5) || 'Title must be atleast 5 characters',
      (v) =>
        (v && v.length <= 60) ||
        'Title must be less than or equal to 60 characters'
    ],
    dimensionRules: [
      (v) => !!v || 'Dimension is required',
      (v) => (v && v > 1) || 'Dimension must be greater than 1px'
    ]
  }),
  watch: {
    dialog(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.dialog = val
      if (val && !this.isEdit) this.mapItem(null)
    },
    item: {
      handler: function (val) {
        this.mapItem(val)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    close() {
      this.dialog = false
    },
    mapItem(obj) {
      this.mapping = {
        title: obj ? obj.title : null,
        attachment: obj ? obj.icon_object : null,
        description: obj && obj.props ? obj.props.description : null
      }
    },
    setIcon(attachment) {
      this.mapping.attachment = attachment
    },
    save() {
      this.$emit(this.isEdit ? 'update' : 'save', this.mapping)
    }
  }
}
</script>
<style></style>
