<template>
  <v-card flat class="product-builder-v2 rounded-10">
    <v-card-title class="add-product-main-header">
      <v-menu offset-y>
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip top>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                v-bind="attrs"
                class="mr-1 mb-1 text-none form-template-btn"
                color="primary"
                height="35"
                depressed
                v-on="{ ...tooltip, ...menu }"
              >
                <span>Form Templates</span>
                <v-icon right>mdi-menu-swap</v-icon>
              </v-btn>
            </template>
            <span>Form Templates</span>
          </v-tooltip>
        </template>
        <v-list dense v-if="form_templates.length > 0" width="300">
          <v-list-item v-for="(item, index) in form_templates" :key="index">
            <v-list-item-title class="primary--text subtitle-2">
              {{ item.name | ucwords }}
            </v-list-item-title>
            <v-list-item-action>
              <v-icon color="primary" @click="deleteTemplate(item)">
                mdi-delete
              </v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-card flat v-else width="300">
          <Empty headline="No template yet" />
        </v-card>
      </v-menu>
      <v-btn
        depressed
        class="text-none mb-1 mr-1 create-template-btn"
        height="35"
        color="primary"
        @click="add_form_dialog = true"
      >
        <v-icon left>mdi-plus</v-icon>
        Create Form Template
      </v-btn>
      <v-spacer />
    </v-card-title>
    <v-card-text>
      <v-card flat outlined>
        <v-card-title
          :class="isEdit ? 'justify-space-between' : 'justify-end'"
          class="py-2 primary d-flex align-center"
        >
          <v-btn
            color="white"
            depressed
            small
            v-if="isEdit"
            class="ma-1 px-5 text-none primary--text"
            @click="createNew"
          >
            <span class="fw-600">Create New</span>
          </v-btn>
          <v-btn
            color="white"
            depressed
            small
            class="ma-1 px-5 text-none primary--text"
            @click="$router.go(-1)"
          >
            <span class="fw-600">Close</span>
          </v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-form v-model="valid">
            <v-stepper v-model="stepper" class="product-stepper">
              <v-stepper-header>
                <v-stepper-step
                  class="template-step"
                  :rules="[rule_1]"
                  :complete="stepper > 1"
                  step="1"
                  style="color: blue"
                >
                  <span class="primary--text fs-16 fw-500">
                    Upload Template
                  </span>
                  <small class="caption">
                    Select and upload template previews and category
                  </small>
                </v-stepper-step>
                <v-icon>mdi-chevron-right</v-icon>
                <v-stepper-step
                  class="template-step"
                  :complete="stepper > 2"
                  step="2"
                  :rules="[rule_2]"
                >
                  Create Order Form
                  <small class="caption"> Add additional fields. </small>
                </v-stepper-step>
                <v-icon>mdi-chevron-right</v-icon>
                <v-stepper-step
                  class="template-step"
                  :complete="stepper > 3"
                  step="3"
                  :rules="[rule_3]"
                >
                  Enter Template Information
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-content step="1">
                <v-card flat class="mb-2 pa-1">
                  <v-card-text>
                    <v-row
                      dense
                      align="center"
                      class="flex-column"
                      justify="center"
                    >
                      <v-col :cols="mdAndUp ? 4 : 12">
                        <label class="subtitle-2 primary--text">
                          Select category <span class="red--text">*</span>
                        </label>
                        <div class="d-flex align-center justify-space-between">
                          <v-select
                            hide-details="auto"
                            outlined
                            dense
                            clearable
                            v-model="selected_category"
                            filled
                            :items="categories"
                            item-text="title"
                            item-value="id"
                          >
                            <template v-slot:selection="{ item }">
                              <v-chip label color="primary" small class="my-1">
                                <span>{{ item.title }}</span>
                              </v-chip>
                            </template>
                          </v-select>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="text-none primary px-5 rounded-10 mx-1"
                                color="light"
                                dark
                                depressed
                                height="40"
                                v-bind="attrs"
                                icon
                                v-on="on"
                                @click="add_main_category_dialog = true"
                              >
                                <v-icon>mdi-book-plus</v-icon>
                              </v-btn>
                            </template>
                            <span>Add new category</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-if="!selected_category"
                          class="red--text text-left caption"
                        >
                          Select category
                        </div>
                      </v-col>
                    </v-row>
                    <v-row
                      dense
                      align="center"
                      class="flex-column"
                      justify="center"
                    >
                      <v-col :cols="mdAndUp ? 4 : 12">
                        <label class="subtitle-2 primary--text">
                          Select Types of Graphics
                          <span class="red--text">*</span>
                        </label>
                        <div class="d-flex align-center justify-space-between">
                          <v-select
                            hide-details="auto"
                            outlined
                            dense
                            clearable
                            :disabled="!selected_category"
                            filled
                            v-model="selected_graphic_type"
                            :items="selectableSubcategories"
                            item-text="title"
                            item-value="id"
                          >
                            <template v-slot:selection="{ item }">
                              <v-chip label color="primary" small class="my-1">
                                <span>{{ item.title }}</span>
                              </v-chip>
                            </template>
                          </v-select>
                          <v-tooltip top v-if="can_add_subcategory">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="text-none primary px-5 rounded-10 mx-1"
                                color="light"
                                dark
                                :disabled="!selected_category"
                                depressed
                                height="40"
                                v-bind="attrs"
                                icon
                                v-on="on"
                                @click="add_sub_category_dialog = true"
                              >
                                <v-icon>mdi-book-plus-multiple</v-icon>
                              </v-btn>
                            </template>
                            <span>Add new type of graphics</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-if="!selected_graphic_type"
                          class="red--text text-center caption"
                        >
                          Select type of graphics
                        </div>
                      </v-col>
                    </v-row>
                    <input
                      class="d-none"
                      :accept="settings.offers_files"
                      @change="onFileChange"
                      multiple
                      ref="file_upload"
                      type="file"
                    />
                    <v-sheet
                      max-width="100%"
                      height="300"
                      class="my-2"
                      v-if="images.length > 0"
                    >
                      <v-chip-group
                        class="pa-4"
                        :show-arrows="showArrows"
                        mandatory
                      >
                        <v-hover
                          v-slot:default="{ hover }"
                          v-for="(image, key) in images"
                          :key="key"
                        >
                          <v-card
                            height="250"
                            flat
                            width="200"
                            class="fill-height ma-2 d-flex align-center"
                          >
                            <v-overlay
                              absolute
                              class="fill-height"
                              :value="hover"
                            >
                              <v-btn
                                color="error"
                                class="text-none"
                                depressed
                                @click="deleteImage(key, image)"
                              >
                                Remove
                              </v-btn>
                            </v-overlay>
                            <Attachment
                              :item="image"
                              animated
                              height="250px"
                            ></Attachment>
                          </v-card>
                        </v-hover>
                        <v-card
                          flat
                          height="250"
                          width="200"
                          max-width="200"
                          class="fill-height ma-2 d-flex align-center"
                        >
                          <v-btn
                            :disabled="noSelectedCategoryAndSubCategory"
                            large
                            depressed
                            @click="$refs.file_upload.click()"
                            color="primary"
                            width="120"
                            class="text-none mx-auto px-5"
                          >
                            Upload More
                          </v-btn>
                        </v-card>
                      </v-chip-group>
                    </v-sheet>
                    <empty
                      v-else
                      headline="Upload template preview(s)"
                      icon="mdi-upload"
                      class="template-upload-icon"
                    >
                      <template v-slot:extra>
                        <v-btn
                          :disabled="noSelectedCategoryAndSubCategory"
                          large
                          elevation="1"
                          @click="$refs.file_upload.click()"
                          text
                          width="120px"
                          style="margin-top: 20px"
                          class="text-none"
                        >
                          <span style="color: #223a6b; font-weight: 700">
                            Upload
                          </span>
                        </v-btn>
                      </template>
                    </empty>
                  </v-card-text>
                  <v-card-actions class="px-0">
                    <v-btn
                      min-width="120"
                      v-if="!isEdit"
                      text
                      elevation="1"
                      :disabled="images.length === 0"
                      color="warning"
                      class="text-none"
                      @click="images = []"
                    >
                      <v-icon left color="#223A6B">
                        mdi-trash-can-outline
                      </v-icon>
                      <span style="color: #223a6b">Remove all</span>
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      min-width="120"
                      text
                      elevation="1"
                      :disabled="!rule_1()"
                      color="primary"
                      class="text-none"
                      @click="stepper = 2"
                    >
                      <span style="color: #223a6b">Next</span>
                      <v-icon right size="30px" color="#223A6B">
                        mdi-menu-right
                      </v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-card flat class="mb-2">
                  <v-card-text class="py-1">
                    <v-radio-group v-model="form_type" row>
                      <v-radio
                        label="Same"
                        value="same"
                        off-icon="mdi-checkbox-blank-outline"
                        on-icon="mdi-check"
                        class="step-radio-input"
                      ></v-radio>
                      <v-radio
                        label="Unique"
                        value="unique"
                        off-icon="mdi-checkbox-blank-outline"
                        on-icon="mdi-check"
                        class="step-radio-input"
                      ></v-radio>
                    </v-radio-group>
                    <template v-if="form_type === 'same'">
                      <v-row
                        dense
                        class="border-1 rounded-10 border-color--grey mb-1"
                      >
                        <v-col
                          cols="12"
                          md="6"
                          sm="12"
                          xs="12"
                          align="center"
                          align-self="center"
                          class="wrap"
                        >
                          <v-sheet class="mx-auto" flat>
                            <v-slide-group class="pa-2" show-arrows>
                              <v-slide-item
                                v-for="(image, key) in images"
                                :key="key"
                              >
                                <v-card
                                  color="grey lighten-1"
                                  class="ma-4 d-flex align-center justify-center"
                                  height="250"
                                  width="200"
                                >
                                  <Attachment
                                    height="250px"
                                    :item="image"
                                  ></Attachment>
                                </v-card>
                              </v-slide-item>
                            </v-slide-group>
                          </v-sheet>
                        </v-col>
                        <!-- <v-divider vertical /> -->
                        <v-col
                          cols="12"
                          md="6"
                          sm="12"
                          xs="12"
                          class="d-flex align-center justify-center"
                        >
                          <FormMaker
                            :templates="form_templates"
                            v-model="same_form"
                          />
                        </v-col>
                      </v-row>
                    </template>
                    <template v-else>
                      <v-row
                        class="border-1 rounded-10 border-color--grey d-flex align-content-center mb-1"
                        dense
                        v-for="image in images"
                        :key="image.uuid"
                      >
                        <v-col
                          cols="5"
                          class="d-flex align-center justify-center"
                        >
                          <Attachment
                            :item="image"
                            height="250px"
                            classes="mx-auto"
                          />
                        </v-col>
                        <v-col class="d-flex align-center justify-center">
                          <FormMaker
                            :templates="form_templates"
                            v-model="image.form"
                          />
                        </v-col>
                      </v-row>
                    </template>
                    <v-row class="border-1 rounded-10 border-color--grey" dense>
                      <v-col sm="12" md="6" class="mx-auto">
                        <h3 class="subtitle-2 primary--text px-2">
                          Add common fields (Optional)
                        </h3>
                        <FormMaker
                          :templates="form_templates"
                          v-model="common_form"
                        />
                      </v-col>
                    </v-row>
                    <v-card-actions class="px-0">
                      <v-btn
                        min-width="120"
                        text
                        elevation="1"
                        class="text-none"
                        @click="stepper = 1"
                      >
                        <v-icon left style="color: #223a6b" size="30px">
                          mdi-menu-left
                        </v-icon>
                        <span style="color: #223a6b">Back</span>
                      </v-btn>
                      <v-spacer />
                      <v-btn
                        min-width="120"
                        text
                        elevation="1"
                        color="primary"
                        :disabled="!rule_2()"
                        class="text-none"
                        @click="stepper = 3"
                      >
                        <span style="color: #223a6b">Next</span>
                        <v-icon right size="30px">mdi-menu-right</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card-text>
                </v-card>
              </v-stepper-content>

              <v-stepper-content step="3">
                <v-card flat class="mb-2 pa-1">
                  <v-card-text
                    :style="mdAndUp ? `max-width: 800px` : ''"
                    class="mx-auto"
                  >
                    <v-row dense align="center" justify="center">
                      <v-col :cols="mdAndUp ? 6 : 12" class="pa-sm-1 pa-md-3">
                        <v-carousel
                          cycle
                          height="350"
                          hide-delimiters
                          hide-delimiter-background
                          show-arrows-on-hover
                        >
                          <v-carousel-item
                            v-for="(slide, i) in images"
                            :key="i"
                          >
                            <v-sheet height="100%">
                              <v-row
                                class="fill-height ma-3"
                                align="center"
                                justify="center"
                              >
                                <div class="pa-2">
                                  <Attachment
                                    :item="slide"
                                    height="250px"
                                  ></Attachment>
                                </div>
                              </v-row>
                            </v-sheet>
                          </v-carousel-item>
                        </v-carousel>
                      </v-col>
                      <v-col
                        :cols="mdAndUp ? 6 : 12"
                        class="d-flex flex-column"
                      >
                        <label
                          for="title"
                          class="primary--text fw-700 fs-16 pl-1"
                        >
                          Title *
                        </label>
                        <v-text-field
                          v-model="title"
                          dense
                          outlined
                          clearable
                          class="step-3-template"
                          placeholder="Enter Title"
                          :rules="titleRules"
                        />
                        <label
                          for="price"
                          class="primary--text fw-700 fs-16 pl-1"
                        >
                          Price
                        </label>
                        <vuetify-money
                          :rules="requiredRules"
                          v-model="price"
                          outlined
                          dense
                          class="step-3-template"
                          clearable
                          placeholder="Enter price"
                          :options="options"
                        />
                        <label
                          for="description"
                          class="primary--text fw-700 fs-16 pl-1"
                        >
                          Description
                        </label>
                        <v-textarea
                          v-model="description"
                          :rows="3"
                          dense
                          auto-grow
                          outlined
                          class="step-3-template"
                          placeholder="Enter description"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      min-width="120"
                      class="text-none mx-1"
                      text
                      elevation="1"
                      @click="stepper = 2"
                    >
                      <v-icon left size="30px">mdi-menu-left</v-icon>
                      <span style="color: #223a6b">Back</span>
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      min-width="120"
                      elevation="1"
                      depressed
                      color="primary"
                      :disabled="!rule_3() || btnloading"
                      :loading="btnloading"
                      class="text-none"
                      @click="save"
                    >
                      {{ save_label | ucwords }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
            </v-stepper>
          </v-form>
        </v-card-text>
      </v-card>
    </v-card-text>
    <CategoryDialog
      v-model="add_main_category_dialog"
      :item="null"
      @save="handleAddMainCategory"
    ></CategoryDialog>
    <GraphicTypeDialog
      v-model="add_sub_category_dialog"
      :category="activeCategory"
      :item="null"
      @save="handleAddSubCategory"
    ></GraphicTypeDialog>
    <OrderTemplateDialog
      :dialog.sync="add_form_dialog"
      @save="handleSaveTemplate"
    ></OrderTemplateDialog>
    <DeleteDialog
      v-model="update_product_with_remove_image_dialog"
      title="Confirmation required"
      :text-content="update_confirm_text"
      delete-button-text="Proceed Update"
      @delete="updateProduct"
    ></DeleteDialog>
  </v-card>
</template>

<script>
import FormMaker from './components/FormMaker.vue'
import request from '@/services/axios_instance'
import CategoryDialog from '@/modules/OffersOrders/components/CategoryDialog.vue'
import GraphicTypeDialog from '@/modules/OffersOrders/components/GraphicTypeDialog.vue'
import { mapActions, mapGetters } from 'vuex'
import OrderTemplateDialog from './components/OrderTemplateDialog.vue'
import DeleteDialog from '../../common/DeleteDialog.vue'
import { offers_mixin } from '../OffersOrders/mixin'
import _ from 'lodash'
export default {
  name: 'ProductUploader',
  components: {
    DeleteDialog,
    OrderTemplateDialog,
    CategoryDialog,
    GraphicTypeDialog,
    FormMaker
  },
  mixins: [offers_mixin],
  data: () => ({
    add_main_category_dialog: false,
    add_sub_category_dialog: false,
    showArrows: false,
    form_templates: [],
    stepper: 1,
    images: [],
    maxFiles: 3,
    same_form: [],
    common_form: [],
    title: null,
    titleRules: [
      (v) => !!v || 'Title is required',
      (v) => (v && v.length >= 5) || 'Title must be atleast 5 characters',
      (v) =>
        (v && v.length <= 60) ||
        'Title must be less than or equal to 60 characters'
    ],
    description: null,
    price: null,
    form_type: 'same',
    category: null,
    closeOnContentClick: true,
    options: {
      locale: 'en-US',
      prefix: '$',
      suffix: '',
      length: 11,
      precision: 2
    },
    valid: false,
    btnloading: false,
    isEdit: false,
    id: null,
    category_selector: false,
    category_dialog: false,
    add_form_dialog: false,
    images_to_be_remove: [],
    update_product_with_remove_image_dialog: false,
    selected_category: null,
    selected_graphic_type: null
  }),
  computed: {
    ...mapGetters(['user']),
    ...mapGetters('cart', ['categories']),
    save_label() {
      return this.isEdit ? 'Update' : 'Save'
    },
    activeCategory() {
      if (!this.selected_category) return null
      return this.categories.find((i) => i.id === this.selected_category)
    },
    can_add_subcategory() {
      return (
        this.activeCategory &&
        this.activeCategory.company_id === this.user.company.id
      )
    },
    noSelectedCategoryAndSubCategory() {
      return !this.selected_category || !this.selected_graphic_type
    },
    selectableSubcategories() {
      if (!this.selected_category) return []
      return this.activeCategory.graphic_types
    },
    payload() {
      return {
        images: this.images,
        common_form: this.common_form,
        same_form: this.same_form,
        title: this.title,
        description: this.description,
        price: this.price,
        category_id: this.selected_category,
        graphic_type_id: this.selected_graphic_type,
        form_type: this.form_type,
        removed_attachments: this.images_to_be_remove
      }
    },
    paths() {
      return [
        {
          text: 'Dashboard',
          disabled: false,
          route: { name: 'default-content' }
        },
        {
          text: 'Offers',
          disabled: false,
          route: { name: `offers`, params: { type: 'products' } }
        },
        { text: 'New Product', disabled: true, route: null }
      ]
    },
    update_confirm_text() {
      return `This update involved removing ${this.images_to_be_remove.length} template(s). These template(s) will also removed from other existing orders`
    }
  },
  created() {
    this.getFormTemplates()
    this.getCategories()
  },
  watch: {
    '$route.params.id': {
      handler: function (val) {
        this.images = []
        this.id = val
        this.images_to_be_remove = []
        if (val) {
          this.getProduct(val)
          this.isEdit = true
        } else this.isEdit = false
      },
      immediate: true
    }
  },
  mounted() {
    this.$event.$emit('path-change', this.paths)
  },
  methods: {
    ...mapActions('cart', [
      'getCategories',
      'createNewCategory',
      'createNewGraphicType'
    ]),
    createNew() {
      this.$router.push({ name: 'product_builder' })
    },
    rule_1() {
      return !!(this.images.length > 0 && this.selected_graphic_type)
    },
    rule_2() {
      return !!(this.images.length > 0 && this.form_type)
    },
    rule_3() {
      return !!(this.images.length > 0 && this.title && this.price > 0.0)
    },
    rule_4() {
      return this.rule_1() && this.rule_2() && this.rule_3() ? true : false
    },
    getProduct(id) {
      request.get(`api/products/${id}`).then(({ data }) => {
        this.images = data.previews
        this.common_form = data.form
        this.title = data.title
        this.description = data.description
        this.price = data.sell_price
        this.selected_category = data.category.id
        this.selected_graphic_type = data.graphic_type.id
        this.form_type = data.form_type
        this.same_form = data.previews[0].form || []
      })
    },
    reset() {
      this.images = this.common_form = this.tags = []
      this.title = this.description = this.price = null
    },
    validate(withError) {
      if (!this.title) {
        if (withError) this.appSnackbar('Title is required', 'error')
        return false
      }
      if (!this.price) {
        if (withError) this.appSnackbar('Price is required', 'error')
        return false
      }
      if (!this.selected_graphic_type) {
        if (withError) this.appSnackbar('Type of Graphics is required', 'error')
        return false
      }
      if (this.images.length < 1) {
        if (withError) this.appSnackbar('Product preview is required', 'error')
        return false
      }
      return true
    },
    onFileChange(e) {
      let error = []
      this.showArrows = false
      let selectedFiles = e.target.files
      Array.from(selectedFiles).forEach((file) => {
        if (file.size <= this.settings.fileSizeMaxUploadLimit) {
          let type = file.type.split('/')[0]
          if (!['image', 'video'].includes(type)) {
            error.push(`${file.name} is not an image or video file. Skipped`)
          } else {
            let formData = new FormData()
            formData.append('file', file)
            request.post(`api2/attachments/dropzone`, formData).then(
              ({ data }) => {
                this.images.push(data)
              },
              (error) => {
                console.log(error)
              }
            )
          }
        } else {
          error.push(`${file.name} exceed allowed max filesize limit`)
        }
      })
      this.$nextTick(() => {
        if (error.length > 0) {
          this.appSnackbar(error.join('<br>'), 'error')
        }
        this.showArrows = 'always'
      })
    },
    save() {
      if (this.validate(true)) {
        if (this.isEdit) {
          if (this.images_to_be_remove.length > 0)
            this.update_product_with_remove_image_dialog = true
          else this.updateProduct()
        } else this.saveProduct()
      }
    },
    updateProduct() {
      this.btnloading = true
      request
        .put(`api/products/${this.id}`, this.payload)
        .then(({ data }) => {
          this.appSnackbar('Product updated!')
          this.$router.push({ name: 'offers-home' })
        })
        .finally(() => (this.btnloading = false))
    },
    saveProduct() {
      this.btnloading = true
      request
        .post(`api/products`, this.payload)
        .then(({ data }) => {
          this.appSnackbar('Product added!')
          this.$router.push({ name: 'offers-home' })
        })
        .finally(() => (this.btnloading = false))
    },
    deleteImage(index, image) {
      if (image.hasOwnProperty('id')) {
        this.images_to_be_remove.push(image.id)
      }
      this.images.splice(index, 1)
    },
    addCategory() {
      this.category_dialog = true
    },
    handleSaveTemplate(payload) {
      request.post(`api/order-form-templates`, payload).then(({ data }) => {
        this.form_templates.push(data)
        this.appSnackbar('Form template added!')
        this.add_form_dialog = false
      })
    },
    getFormTemplates() {
      request.get(`api/order-form-templates`).then(({ data }) => {
        this.form_templates = data
      })
    },
    deleteTemplate(item) {
      request.delete(`api/order-form-templates/${item.id}`).then(({ data }) => {
        let index = this.form_templates.findIndex((i) => i.id === item.id)
        if (~index) {
          this.form_templates.splice(index, 1)
          this.appSnackbar('Form template deleted!')
        }
      })
    },
    selectedCategory(item) {
      this.category = item
      this.category_selector = false
    },

    handleAddSubCategory(payload) {
      this.createNewGraphicType({
        payload: payload,
        cb: () => {
          this.add_sub_category_dialog = false
          this.appSnackbar('New type of graphics added')
          this.getCategories()
        }
      })
    },
    handleAddMainCategory(category) {
      this.createNewCategory({
        payload: category,
        cb: () => {
          this.add_main_category_dialog = false
          this.appSnackbar('New category added')
          this.getCategories()
        }
      })
    }
  }
}
</script>
<style lang="scss">
.product-builder-v2 {
  .product-stepper {
    .template-step {
      .v-stepper__step__step {
        border-radius: 5px;
        padding: 17px;
        font-weight: 700;
        font-size: 14px;
        background: $blue;
        color: white !important;
        margin-top: -5px;
      }
    }
  }
}
</style>
